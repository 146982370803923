module.exports = {
  comm: {
    home: 'Inicio',
    pricing: 'Precios',
    blog: 'Clonación de voz y API',
    language: 'idioma:',
    select_language: 'seleccionar idioma',
    txt2voice: 'Texto a voz',
    voice2txt: 'Voz a texto',
    voiceclone: 'Clonación de voz',
    video2txt: 'Video a texto',
    footer_help: '¿Necesita ayuda? Envíenos un correo electrónico:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Entrar',
    login_desc: 'Inicie sesión para acceder a su cuenta del Generador de Voz de TikTok',
    logout: 'Salir',
  },
  txt2voice: {
    title: 'Generador de voz TikTok: Generar voces AI TikTok divertidas',
    description: 'Genere y descargue voces AI TikTok divertidas de forma gratuita, como la voz de Jessie, la voz de C3PO, la voz de Ghostface...',
    keywords: 'Generador de voz TikTok, Voz AI TikTok, Generador de voz Tik Tok',
    main_title_p01: 'Generador de voz TikTok',
    main_title_p02: 'Generar voces TikTok divertidas: voz de Jessie, voz de C3PO, voz de Ghostface',
    main_textarea_holder: 'Escriba o pegue texto aquí',
    main_genvoice: 'generar',
    main_generating: 'generando',
    main_input_empty: 'por favor escriba o pegue texto',
    daily_usage_limit_msg: '1) Se ha alcanzado el máximo de uso diario. Por favor, vuelva mañana. 2) Si necesita más uso de forma urgente, envíenos un correo electrónico.',
    text_max_prompt1: 'Por favor limite el número de palabras a',
    text_max_prompt2: '¡palabras o menos!',
    popup_nologin_title: 'Actualice su plan de suscripción',
    popup_nologin_desc: 'Desbloquee más tiempo de generación de voz actualizando a una suscripción premium.',
    popup_nologin_btntext: 'Explorar planes de suscripción',
    popup_nosub_title: 'Actualice su plan de suscripción',
    popup_nosub_desc: 'Desbloquee más tiempo de generación de voz actualizando a una suscripción premium.',
    popup_nosub_btntext: 'Explorar planes de suscripción',
    popup_sublimit_title: 'Suscripción agotada, por favor actualice',
    popup_sublimit_desc: 'Mejore su experiencia con nuestras funciones premium y acceso ilimitado.',
    popup_sublimit_btntext: 'Ir a comprar más',
  },
  pricing: {
    new_features_alert: "📣 ¡Hemos lanzado servicios de 🎙️ Clonación de voz y 🤖 API para miembros de pago! (Después del pago, envíe un correo electrónico a tiktokaivoicetool@gmail.com para obtener ayuda con estas funciones). Además, no dude en enviarnos un correo electrónico para cualquier solicitud de personalización😃",
    pricing_title: 'Desde creadores individuales hasta las empresas más grandes, tenemos un plan para ti.',
    pricing_desc: 'Más de 100 voces naturales y humanas. Más de 15 idiomas. y casi los precios más bajos para todos',
    sub_free_name: 'Gratis',
    sub_free_desc: 'Para individuos que quieren probar el audio AI más avanzado',
    sub_free_content: [
      'Límite de 1000 caracteres por mes',
      '5 generaciones por día',
    ],
    sub_starter_name: 'Inicial',
    sub_starter_desc: 'Para creadores que producen contenido premium para una audiencia global',
    sub_starter_content: [
      '1 clon de voz',
      'Límite de 300,000 caracteres por mes (aproximadamente 7 horas de audio)',
      'Descargas ilimitadas',
      'Soporte por correo electrónico en 72 horas',
      'Acceso prioritario a nuevas voces y funciones',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Para creadores que están expandiendo su producción de contenido',
    sub_pro_content: [
      'Acceso a API',
      '3 clones de voz',
      'Límite de 1,000,000 de caracteres por mes (aproximadamente 24 horas de audio)',
      'Descargas ilimitadas',
      'Soporte por correo electrónico en 48 horas',
      'Acceso prioritario a nuevas voces y funciones',
    ],
    period_year: 'Anual',
    period_month: 'Mensual',
    period_month_short: 'mes',
    billed_year: 'Facturado anualmente',
    most_popular: 'Más popular',
    discount_quantity: 'Ahorre $48',
    buy_btntext: 'Comenzar',
    pay_succ: '¡Pago exitoso!',
    pay_succ_desc: 'Gracias por su compra. Su transacción se ha procesado con éxito.',
    pay_succ_btntext: 'Comience a usar TikTok Voice',
    pay_fail: '¡Pago fallido!',
    pay_fail_desc: "Lo sentimos, no pudimos procesar su transacción. Por favor, inténtelo de nuevo o contacte a nuestro equipo de soporte (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Volver a la página principal',
  },
  setting: {
    setting: 'Configuraciones',
    setting_title: 'Gestione su cuenta, uso y suscripción aquí.',
    basic_info: 'Información básica',
    user_name: 'Nombre',
    user_email: 'Correo electrónico',
    user_account: 'Cuenta',
    user_subscript: 'Plan actual',
    user_usage: 'Uso',
    manage_subscript: 'Gestionar suscripción',
    use_limit_promote: "Ha utilizado 5000 de su límite mensual de 10000 caracteres.",
    not_logged_in: 'No ha iniciado sesión',
    go_to_login: 'Ir a iniciar sesión >>',
  },
  login: {
    title: "Generador de Voz de TikTok",
    subtitle: "Inicie sesión para acceder a su cuenta del Generador de Voz de TikTok",
    alreadyLoggedIn: "Ya ha iniciado sesión",
    goToHome: "Ir a la página de inicio"
  },
  faq: {
    quest1: 'Generador de voz TikTok - La mejor herramienta de voz AI TikTok',
    answer1_1: 'Es una herramienta generadora de voces AI TikTok desarrollada por la última tecnología tts TikTok, puede generar voces TikTok como la voz de Jessie (voz femenina), la voz de Siri, la voz de Ghostface, la voz de C3PO (voz de robot), la voz profunda (narrador), la voz de Skye, la voz cálida, la voz de Bestie, la voz de Héroe (voz de Chiris), la voz empática, la voz seria, la voz de Joey, la voz de Stitch, la voz de Stormtrooper (Star Wars), la voz de Rocket (Guardians of the Galaxy).',
    answer1_2: 'Las voces AI TikTok que pronto serán compatibles incluyen: la voz de Derek, la voz de Trickster, la voz de Austin Butler, la voz de reportero de noticias, la voz de Adam, la voz de perro, la voz de Miley Cyrus, la voz de Alexa, la voz de extraterrestre, la voz de animal, la voz de bebé, la voz de computadora, la voz de ardilla, la voz de eco, la voz de NPR.',
    answer1_3: 'Además, la herramienta generadora de voces TikTok admite varios otros idiomas, incluidos chino, japonés, coreano, vietnamita, tailandés, hindi, persa, ruso, alemán, francés, rumano, checo, español, portugués, bengalí, italiano, árabe, urdu, chino tradicional y malayo.',
    answer1_4: 'Si necesita urgentemente una voz específica, envíeme un correo electrónico.',

quest2: '¿Cuáles son las ventajas del generador de voz TikTok?',
    answer2_1: '- La herramienta generadora de voces TikTok puede generar varios tipos de voces, que a menudo se utilizan en los videos de TikTok.',
    answer2_2: '- Es el último generador de voces AI que puede generar voces tts TikTok similares a las humanas.',
    answer2_3: '- Es más conveniente para la edición de videos en una PC.',
    answer2_4: '- Puede utilizar algunas voces que actualmente no se encuentran en la aplicación tts de TikTok.',

quest3: '¿Cómo usar el generador de voz TikTok?',
    answer3_1: 'El generador de voz TikTok es muy fácil de usar:',
    answer3_2: '- Seleccione el idioma y el acento de la voz.',
    answer3_3: '- Escriba el texto que se va a convertir en voz en el cuadro de entrada.',
    answer3_4: '- Presione el botón de generar y espere unos segundos.',
    answer3_5: '- Reproduzca o descargue la voz AI TikTok.',

quest4: '¿Cuáles son las voces TikTok más famosas y divertidas?',
    answer4_1: 'La voz masculina más famosa de TikTok es la voz profunda, oficialmente llamada narrador.',
    answer4_2: 'Para la voz TikTok más divertida, recomiendo personalmente la voz de Ghostface y la voz de C3PO. Estas se utilizan a menudo para doblar videos divertidos de TikTok porque son muy reconocibles y pueden atraer rápidamente a los espectadores.',

quest5: '¿Cuáles son las voces TikTok más molestas?',
    answer5_1: 'Creo que realmente no hay una voz TikTok "molesta".',
    answer5_2: 'Más bien, depende del contexto y del contenido en el que se utilice la voz, lo que puede incomodar a algunas personas. Por ejemplo, algunas personas pueden encontrar molesta la voz de Jessie porque apareció con demasiada frecuencia en TikTok durante un cierto período. Sin embargo, debido a su tono y calidad, en realidad es un efecto de voz TikTok fantástico. Además, la actriz de voz TikTok detrás de ella es muy profesional y también publica sus propios videos en TikTok.',
    answer5_3: 'Por lo tanto, sugiero que todos dejen de lado las preocupaciones y prejuicios, escuchen varias veces las voces de esta herramienta y con paciencia encuentren el efecto de voz TikTok que mejor les convenga.',

quest6: '¿Cómo usar el generador de voz TikTok para generar la voz de Jessie?',
    answer6_1: '- En el primer menú desplegable de la herramienta generadora de voz TikTok, seleccione English (US), luego en el otro menú desplegable, seleccione la voz de Jessie (voz femenina).',
    answer6_2: '- Escriba el texto en el cuadro de entrada y haga clic en el botón de generar.',
    answer6_3: '- Espere unos segundos o hasta diez segundos, escuchará la voz AI. En la barra de herramientas, encuentre el botón con una flecha hacia abajo y haga clic en él para descargar.',

quest7: '¿Cómo usar el generador de voz TikTok para generar la voz de C3PO?',
    answer7_1: '- En el primer menú desplegable de la herramienta generadora de voz TikTok, seleccione English (US), luego en el otro menú desplegable, seleccione la voz de C3PO (voz de robot).',
    answer7_2: '- Escriba el texto en el cuadro de entrada y haga clic en el botón de generar.',
    answer7_3: '- Espere unos segundos o hasta diez segundos, escuchará la voz AI. En la barra de herramientas, encuentre el botón con una flecha hacia abajo y haga clic en él para descargar.',

quest8: '¿Cómo usar el generador de voz TikTok para generar la voz de Ghostface?',
    answer8_1: '- En el primer menú desplegable de la herramienta generadora de voz TikTok, seleccione English (US), luego en el otro menú desplegable, seleccione la voz de Ghostface.',
    answer8_2: '- Escriba el texto en el cuadro de entrada y haga clic en el botón de generar.',
    answer8_3: '- Espere unos segundos o hasta diez segundos, escuchará la voz AI. En la barra de herramientas, encuentre el botón con una flecha hacia abajo y haga clic en él para descargar.',

quest9: '¿Cómo usar el generador de voz TikTok para generar la voz de Siri?',
    answer9_1: '- En el primer menú desplegable de la herramienta generadora de voz TikTok, seleccione English (US), luego en el otro menú desplegable, seleccione la voz de Siri.',
    answer9_2: '- Escriba el texto en el cuadro de entrada y haga clic en el botón de generar.',
    answer9_3: '- Espere unos segundos o hasta diez segundos, escuchará la voz AI. En la barra de herramientas, encuentre el botón con una flecha hacia abajo y haga clic en él para descargar.',

quest10: '¿Cómo agregar el efecto de voz TikTok a un video TikTok?',
    answer10_1: 'Si desea saber cómo usar las voces oficiales de TikTok en TikTok, pronto escribiré una publicación de blog dedicada a este tema.',
    answer10_2: 'Aquí explicaré cómo publicar su voz en TikTok después de generarla y descargarla desde el generador de voz TikTok.',
    answer10_3: '1. Si ha generado la voz TikTok en su PC y desea cargarla en TikTok o en cualquier otra aplicación de edición de videos en su teléfono, debe transferir el archivo de voz a su teléfono. Para los iPhones, puede utilizar airDrop para la transferencia. Para los teléfonos Android, no estoy muy familiarizado con este proceso, pero seguramente encontrará métodos y herramientas para hacerlo.',
    answer10_4: '2. Una vez que el archivo de voz se haya transferido a su teléfono, puede abrir TikTok:',
    answer10_5: '- Toque el botón "+" en la parte inferior de la interfaz, seleccione el video en su teléfono.',
    answer10_6: '- Después de cargar el video, encuentre un icono cuadrado en el lado derecho de la pantalla para ingresar a la página de edición.',
    answer10_7: '- En la parte inferior de la página, encuentre el botón "agregar voz" y toque para seleccionar el archivo de voz recién transferido.',
    answer10_8: '- En esta interfaz, puede realizar algunos ajustes al video, y luego toque el botón en la esquina superior derecha para publicarlo.',

quest11: '¿Es gratis TikTok Voice?',
    answer11: 'Sí, es una herramienta gratuita de generación de voces AI TikTok de texto a voz.',

quest12: '¿Dónde puedo generar una voz TikTok popular en mi video TikTok?',
    answer12: 'Puede visitar https://tiktokvoice.net/ para agregar voces AI TikTok a su video.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}